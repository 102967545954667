import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    site_data: null,
    events: [],
    projects: [],
    firstTime: true,

    selectedProject: null,
    selectedEvent: null
  },
  mutations: {
    set_data (state, payload) {
      state.site_data = payload
    },
    set_events (state, payload) {
      state.events = payload
    },
    set_projects (state, payload) {
      state.projects = payload
    },
    set_firstTime (state, payload) {
      state.firstTime = payload
    },

    selectProject (state, project_id) {
      state.selectedEvent = null
      if(state.selectedProject == project_id) {
        state.selectedProject = null
      } else {
        state.selectedProject = project_id
      }
    },

    selectEvent (state, event_id) {
      if(state.selectedEvent == event_id) {
        state.selectedEvent = null
      } else {
        state.selectedEvent = event_id
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
