import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import store from '../store'
import Map from '../assets/landing-map.svg'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import(/*webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      requiresFirstTime: true
    }
  },
  {
    path: '/',
    name: 'Intro',
    component: () => import(/*webpackChunkName: "Intro" */ '../views/Intro.vue'),
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: () => import(/*webpackChunkName: "Event" */ '../views/Event.vue'),
    meta: { requiresFirstTime: false }
  },
  {
    path: '/story',
    name: 'Story',
    component: () => import(/*webpackChunkName: "Story" */ '../views/Story.vue'),
    meta: { requiresFirstTime: false }
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {

  // force intro if first load
  if( to.meta.requiresFirstTime) {

    // preload map
    loadImage(
      Map
    ).then(function() {
      next(vm => {
        console.log(vm.page)
      })
    })

    // force route to intro if first load / refresh
    if( store.state.firstTime ) {
      next({
        name: "Intro"
      })
    } else {
      next();
    }
    // need to show intro
  } else {
    next();
  }

  if( to.name == 'Home' ) {
    console.log('preload image', to)
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
})


function loadImage(url) {
  return new Promise(function(resolve, reject) {
    var img = new Image()
    img.onload = function() {
      resolve(url)
    }
    img.onerror = function() {
      reject(url)
    }
    img.src = url
    console.log(`img`, img)
  })
}

export default router
