<template>

  <nav v-if="projects.length" class="flex flex-col border-t border-b-black shadow-xl">

      <div class="grid grid-cols-1 divide-y divide-black lg:divide-y-0 lg:divide-x z-40 bg-white transition">

        <!-- <div class="col-span-1 px-8 py-4 bg-yellow-50 text-center lg:text-left">
          <h4 class="font-serif font-bold text-base lg:text-xl mb-4">
            This map is a conceptual representation of land and territory...
          </h4>
          <router-link :to="{ name: 'Story' }" >
            <nag-button button-text="Read Artist Statement" size="sm" />
          </router-link>
        </div> -->

        <!-- Projects -->
        <div>
          <swiper ref="swiperProjects" :options="swiperProjectsOptions" class="h-full">
            <swiper-slide v-for="project in sortedProjects" :key="project.id" class="group first-of-type:border-l border-r border-black -ml-px mr-px">
              <button @click.prevent="$store.commit('selectProject', project.id)" class="flex flex-col items-center justify-between p-4 border-b-4 w-36 lg:w-36 group-hover:border-yellow-400" :class="selectedProject == project.id ? 'border-yellow-400' : 'border-gray-200'">
                <div class="bg-yellow-400 rounded-full p-0 transition-all">
                  <img class="w-10" :src="project.data.icon.url" :alt="project.data.icon.alt" />
                </div>
                <p class="text-sm lg:text-base leading-regular">
                  {{ project.data.title[0].text }} 
                </p>
                <ion-icon :name="selectedProject == project.id ? 'close' : 'chevron-down'" class="text-sm"></ion-icon>
              </button>
            </swiper-slide>
          </swiper>
        </div>

      </div>

      <!-- Event list area-->
      <div class="py-6 border-t border-black " v-if="selectedProject">
        <swiper ref="swiperEvents" :options="swiperEventsOptions">
          <swiper-slide  v-for="event in events.filter(e => e.data.project.id == selectedProject)" :key="event.id" class="px-4">    
            <router-link :to="{ name: 'Event', params: { id: event.uid }}" class="flex border border-black bg-white group w-full divide-x divide-black" style="height: 10rem;">
              <div class="w-36 lg:flex-shrink-0 bg-yellow-400 overflow-hidden">
                <img :src="event.data.featured_image.url" class="object-cover h-full w-full group-hover:scale-110 transition-all duration-500" />
              </div>
              <div class="w-full flex flex-col divide-y divide-black justify-between">
                <div class="px-4 py-3 flex items-center h-full">
                  <h4 class="font-serif text-xl font-bold italic">
                    {{ event.data.title[0].text }}
                  </h4>
                </div>
                <div class="flex divide-x divide-black justify-between">
                  <div class="px-4 py-3 text-sm w-full">
                    {{ formattedDate(event) }}
                  </div>
                  <div class="flex relative overflow-hidden h-full flex-shrink-0 px-4 py-3 self-center">
                    <nag-icon icon-name="arrow-forward"/>
                  </div>
                    <!-- <ion-icon name="arrow-forward-outline" class="w-4 h-4 p-2 bg-yellow-400 text-black rounded-full group-hover:bg-black group-hover:text-white group-hover:-rotate-45 z-20"></ion-icon> -->
                </div>
              </div>
            </router-link>
          </swiper-slide >
          <template v-slot:scrollbar>
            <div class="swiper-scrollbar mt-4"></div>
          </template>
        </swiper>
      </div>
    
  </nav>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "NavMenu",
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperProjectsOptions: {
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
      },
      swiperEventsOptions: {
        observer: true,
        observeParents: true,
        slidesPerView: 1.5,
        mousewheel: true,
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: false
        },
          breakpoints: {
          '768': {
            slidesPerView: 2.5,
          },
          '1024': {
            slidesPerView: 2.5,
          },
          '1440': {
            slidesPerView: 3.5,
          }
        }
      },
      dateOptions: {
        dateStyle: 'medium'
      }
    }
  },
  computed: {

    siteData () {
      return this.$store.state.site_data
    },
    projects () {
      return this.$store.state.projects
    },
    projectOrder () {
      return this.$store.state.site_data.projects
    },
    sortedProjects () {
      return this.projectOrder.map(p => {
        return this.projects.find(project => project.id == p.linked_project.id)
      })
    },
    events () {
      return this.$store.state.events
    },

    selectedProject () {
      return this.$store.state.selectedProject
    },
    selectedProjectIndex () {
      let index = 0
        if(this.selectedProject){
          index = this.sortedProjects.findIndex(p => p.id == this.selectedProject)
      }
      return index
    },
    selectedEvent () {
      return this.$store.state.selectedEvent
    },
    selectedEventIndex () {
      let index = 0
        if(this.selectedEvent){
          let projectEvents = this.events.filter(e => e.data.project.id == this.selectedProject)
          index = projectEvents.findIndex(e => e.id == this.selectedEvent)
      }
      return index
    },

    swiperMenuInstance() {
      return this.$refs.swiperProjects.$swiper
    }

  },
  methods: {
    formattedDate (event) {
      const formattedDate = new Date(event.data.event_date + 'PST').toLocaleDateString("en-ca", this.dateOptions)
      return formattedDate != 'Invalid Date' ? formattedDate : null
    }
  },
  watch: {
    selectedProject () {
      console.log(this.selectedProjectIndex)
      this.swiperMenuInstance.slideTo(this.selectedProjectIndex, 500, false)
    }
  }
}
</script>

<style scoped>

.swiper-container-horizontal > .swiper-scrollbar {
  position: relative;
}

.swiper-slide {
  display: inline-flex;
  width: auto;
  height: auto;
}

</style>