<template>

  <div id="app">
    
    <Nav v-if="loaded" :show-menu="showMenu" @toggle-menu="toggleMenu" class="h-20" />

    <transition name="fade">
      <router-view @hideMenu="showMenu = false" />
    </transition>


  </div>

</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
  name: 'App',
  components: { Nav },
  metaInfo() {
    return {
      title: "Landing - An online home for Nanaimo Art Gallery's offsite programs.",
      meta: [
        { name: 'description', content:  "An online home for Nanaimo Art Gallery's offsite programs."},
        { property: 'og:title', content: "Landing - An online home for Nanaimo Art Gallery's offsite programs."},
        { property: 'og:site_name', content: 'Landing'},
        {property: 'og:type', content: 'website'},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  },
  data () {
    return {
      root_url: process.env.VUE_APP_ROOT_URL,
			showMenu: false,
    }
  },
  async mounted(){
    this.getSiteData()
		this.getEvents()
		await this.getProjects()
  },
  computed: {
    loaded () {
      return this.$store.state.site_data ? true : false
    },
    selectedProject () {
      return this.$store.state.selectedProject
    }
  },
  methods: {
    async getSiteData () {
      // Get the global data for the site (Menus, Header, etc...)
      const response = await this.$prismic.client.getSingle('global')
      console.log(response.data)
      return this.$store.commit('set_data', response.data)
    },
		async getEvents(){
			const response = await this.$prismic.client.query(
				this.$prismic.Predicates.at('document.type','events'),
				{
					pageSize: 100,
				}
			)
      return this.$store.commit('set_events', response.results)

		},
		async getProjects(){
			const response = await this.$prismic.client.query(
				this.$prismic.Predicates.at('document.type','projects'),
				{
					pageSize: 100,
				}
			)
      return this.$store.commit('set_projects', response.results)
		},

    toggleMenu () {
      this.showMenu = !this.showMenu
    }
  },
}
</script>

<style>

html, body, #app {
  height: 100%;
}

a {
  cursor: pointer;
  /* color: var(--n-yellow); */
}

:root {
  --n-yellow: #fcc818;
  --n-lightyellow: #fff7dc;
  --nanaimo-gradient: linear-gradient(90deg, rgba(252,200,24,1) 0%, rgba(255,233,160,1) 100%);
  --nanaimo-gradient-light: linear-gradient(#fff7dc,#fff7dc 140px,#ffffff 0,#ffffff);
  --nanaimo-gradient-light-sm: linear-gradient(#fff7dc,#fff7dc 100px,#ffffff 0,#ffffff);
}

.n-yellow {
  color: var(--n-yellow);
}
.bg-n-yellow {
  background: var(--n-yellow);
}
.bg-n-lightyellow {
  background: var(--n-lightyellow);
  transition: background .5s ease-in-out;
}

.thin {
  --ionicon-stroke-width: 12px;
}
ion-icon {
  font-size: 1.3rem;
}

</style>
