<template>
  <header class="bg-white z-20 sticky top-0">
      <div class="flex items-stretch justify-between pl-6 gap-6 z-30 [ h-20 ] bg-white border-b border-black">

        <a href="http://www.nanaimoartgallery.com" target="_blank" class="flex items-center">
          <img :src="siteData.logo.url" class="h-16"/>
        </a>
        <div class="flex items-center">
          <router-link :to="{ name: 'Home' }">
            <h3 class="text-3xl font-serif font-bold italic hover:text-yellow-400 transition">
              {{ siteData.site_title[0].text }}
            </h3>
          </router-link>
          <!-- <p class="m-0 text-xs pl-6 text-gray-400">
           {{ siteData.site_description[0].text }}
          </p> -->
        
        </div>
        <div class="[ flex text-sm  divide-x divide-black ]">
          <ul class="flex divide-x divide-black">
            <li v-for="link in siteData.navigation_links" :key="link.id" class="group flex gap-2 px-4 h-full hover:bg-black hover:text-white font-bold">
              <router-link :to="`/${link.link.slug}`" class="flex h-full items-center">{{link.link_title}}</router-link>
            </li>
            <li>
              <a class="group [ flex gap-2 px-4 h-full items-center hover:bg-black hover:text-white font-bold ]" @click.prevent="$emit('toggle-menu')">
                Events
                <nag-icon :animate-icon="false" v-if="showMenu" :icon-name="'close'"/>
                <nag-icon :animate-icon="false" v-else :icon-name="'menu'"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div :class="showMenu ? 'block' : 'hidden'" class="w-full absolute -z-10 bg-white border-b border-black animate-moveDown">
        <NavMenu />
      </div>
    </header>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue'

export default {
    name: 'Nav',
    props: ['showMenu'],
    components: { NavMenu },
     computed: {
      siteData () {
        return this.$store.state.site_data
      },
     }
}
</script>

<style>

</style>