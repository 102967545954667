import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrismicVue from '@prismicio/vue'

const accessToken = '' // Leave empty if your repo is public
const endpoint = 'https://nanaimo-landing.cdn.prismic.io/api/v2' // Use your repo name

// Register plugin
Vue.use(PrismicVue, {
    endpoint,
    apiOptions: { accessToken }
})

// Custom interface components for Nanaimo
import Button from './components/Button.vue'
Vue.component('nag-button', Button)
import ButtonIcon from './components/ButtonIcon.vue'
Vue.component('nag-icon', ButtonIcon)

import AllIosIcon from 'vue-ionicons/dist/ionicons-ios.js'
Vue.use(AllIosIcon)
Vue.config.ignoredElements = [/^ion-/]

// import vue-panzoom
import panZoom from 'vue-panzoom'

import './assets/tailwind.css'

Vue.use(panZoom)

// Audio Player
// https://github.com/redxtech/vue-plyr
// Options and styles: https://github.com/sampotts/plyr#options
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
  plyr: {}
})

// Vimeo Player
import vueVimeoPlayer from 'vue-vimeo-player'
Vue.use(vueVimeoPlayer)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

