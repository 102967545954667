<template>
    <div class="icon flex items-center" :class="showCircle ? 'circle' : null">
        <ion-icon :name="iconName" 
        :class="animateIcon ? 'group-hover:-rotate-45' :null"
        class="
        bg-yellow-400 
        text-black 
        rounded-full 
        w-4 h-4 p-0.5 
        z-10 
        thin
        group-hover:bg-white 
        group-hover:text-black
        transform
        transition-all 
        transition-duration-500"
        @click="$emit('click')" ></ion-icon>
    </div>
</template>

<script>
export default {
    name: 'ButtonIcon',
    props: {
        iconName: {
            type: String,
            default: 'arrow-forward'
        },
        showCircle: {
            type: Boolean,
            default: false
        },
        animateIcon: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>
    /* .circle:before {
        content: "";
        background: var(--n-yellow);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1rem;
        transform:scale(0);
        position: absolute;
        transition: transform .35s;
        transition-timing-function: ease-in-out;
        z-index: 0;
    }

    button:hover .circle:before {
        transform:scale(4);
    } */
</style>