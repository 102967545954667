<template>

    <button 
    class="group relative inline-flex items-center bg-white border border-black transition overflow-hidden" 
    :class="[iconPosition == 'left' ? 'flex-row-reverse' : null,  size == 'sm' ? 'py-1 px-2 text-sm gap-2' : 'py-2 px-6 text-base gap-4']" 
    @click="$emit('click')">
        <div class="z-10">{{ buttonText }}</div>
        <nag-icon :icon-name="iconName"/>
    </button>
    
</template>

<script>
export default {
    name: "Button",
    props: {
        buttonText: String,
        size: {
            type: String,
            default: 'base'
        },
        iconName: {
            type: String,
            default: 'arrow-forward'
        },
        animateIcon: {
            type: Boolean,
            default: true     
        },
        iconPosition: {
            type: String,
            default: 'right'  
        }
    }
}
</script>